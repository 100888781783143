<template>
  <div id="scraper-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-card-list"></i>
          <span style="margin-left: 5px"> Log Tier Media </span>
        </b-card-title>
      </b-card-header>
      <!-- body component -->
      <b-card-body class="d-flex align-items-center pb-0 justify-content-end">
        <b-button
          :variant="hide_filled_description ? 'success' : 'outline-success'"
          class="ml-1 btn-icon"
          v-b-tooltip.hover.top="'Exclude Deskripsi yang Sudah Diisi'"
          @click="
            (hide_filled_description = !hide_filled_description), getData()
          "
        >
          <i class="bi bi-check-circle" style="font-size: 20px;"></i>
        </b-button>
        <!-- date picker -->
        <datepicker-component class="ml-1"/>
        <!-- keyword filter -->
        <b-input-group class="input-group-merge ml-1" style="width: 20rem">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Masukkan kata kunci..."
            v-model="key"
            style="height: 3rem"
          />
        </b-input-group>
      </b-card-body>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table component -->
      <table-component
        v-else
        :data="table_data"
        :viewDetail="addDescription"
        :is_row_custom_color="true"
        class="mt-1"
      />
    </b-card>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import datepicker_component from "@/views/components/datePicker.vue";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "LogMediaTier",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    "table-component": dataTable,
    "datepicker-component": datepicker_component,
    "loader-component": loader,
  },
  data() {
    return {
      is_loading: true,
      key: this.$route.query.key || "",
      hide_filled_description: true,
      table_data: {
        page_info: {
          limit: this.$route.query.items ? this.$route.query.items : 1,
          page: this.$route.query.page ? this.$route.query.page : 10,
          total: 0,
        },
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "scraper_name",
            label: "nama scraper",

            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "media_name",
            label: "media terkait",
            thClass: "text-left",
            tdClass: "text-left",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "media_count",
            label: "jumlah media",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "old_tier",
            label: "tier lama",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "new_tier",
            label: "tier baru",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },

          {
            key: "log_tier_description",
            label: "deskripsi",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "created_at",
            label: "tanggal diubah di monalisa",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
        ],
        items: [],
      },
      CancelToken: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "$route.query"() {
      this.table_data.page_info.page = this.$route.query.page
        ? this.$route.query.page
        : 1;
      this.table_data.page_info.limit = this.$route.query.items
        ? this.$route.query.items
        : 10;
      this.table_data.page_info.total = this.getData();
    },
    key() {
      if (this.$route.query.key) {
        let queries = JSON.parse(JSON.stringify(this.$route.query));
        if (queries.key) {
          delete queries.key;
        }
        if ($.param(this.$route.query) != $.param(queries)) {
          this.$router.replace({ query: queries });
        }
      }
      if (this.key.length > 2 || this.key.length == 0) {
        this.updateQuery("page", 1);
        this.getData();
      }
    },
  },
  methods: {
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    getData() {
      if (this.CancelToken) {
        this.CancelToken.cancel();
      }
      this.CancelToken = axios.CancelToken.source();
      this.is_loading = true;
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
        hide_filled_description: this.hide_filled_description,
        page: this.table_data.page_info.page,
        item: this.table_data.page_info.limit,
        ...(this.key ? { key: this.key } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "tier-log?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.CancelToken.token })
        .then((res) => {
          this.table_data.items = res.data.tier_logs_data || [];
          this.table_data.page_info.total = res.data.pagination_info.count || 0;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.table_data.items) {
            this.is_loading = false;
          }
        });
    },
    addDescription(id) {
      const selected_data = this.table_data.items.find((el) => el._id == id);
      if (
        selected_data &&
        selected_data.description &&
        selected_data.description.trim()
      ) {
        const params = {
          scraper_name: selected_data.scraper_name,
          description: selected_data.description || "",
        };
        useJwt.axiosIns
          .put(`tier-log/update/${selected_data._id}`, params)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Berhasil !",
                icon: "AlertCircleIcon",
                variant: "success",
                text: `Deskripsi Telah Ditambahkan!`,
              },
            });
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Gagal !",
                icon: "AlertCircleIcon",
                variant: "danger",
                text: `Terjadi Kesalahan Pada Sistem!`,
              },
            });
          })
          .finally(() => {
            this.getData();
          });
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>
